/*! core.scss | FoundPad  */

/* ==========================================================================
Kit 1 core 
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. CSS Imports
=============================================================================
***/

/* ==========================================================================
0. CSS Imports
========================================================================== */

@import 'partials/theme-default';
@import 'partials/colors';
@import 'partials/layout';
@import 'partials/features';
@import 'partials/buttons';
@import 'partials/timeline';
@import 'partials/team';
@import 'partials/faq';
@import 'partials/blog';
@import 'partials/footer';
@import 'partials/forms';
@import 'partials/pageloader';
@import 'partials/animations';
@import 'partials/overrides';
@import 'partials/ev2019';
@import 'partials/modal-video';
@import 'partials/foundpad-modals';
@import 'partials/dashboard';
@import 'partials/tooltips';
@import 'partials/darkapp';
@import 'partials/darkforms';
@import 'partials/onboarding/onboarding';
@import 'partials/mvp/mvp';
@import 'partials/landing/landing';
@import 'partials/responsive';