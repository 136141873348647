$primary: #34495e;
$primary-accent: #00c3c0;
$secondary: #2b3c4c;
$secondary-accent: #ff8602;
$tertiary: #3b5369;
$pinkiary: #fc6584;
$label-color : #ffffff5e;
$text-color : #b1b7bd;
$line-color: #314559;
$lightgray: #95a2b3
