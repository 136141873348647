@import '../colorvars';

.contentCard {
    background-color: $primary;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding: 30px 35px;
    margin-right: 15px;
}
.step-title{
    color: $lightgray;
}
.step-text {
    color: $secondary;
}
.step-text::after {
    background: url(../../assets/images/mvp/connector.svg) repeat-y top;
    height: 48px;
    left: calc(50% - 2px);
    top: 100%;
    width: 4px;
}