$stream-start-color:#ff860200;
$stream-mid-color:#ff8602;
$rocket-start-color:#5D6D7E00;
$rocket-mid-color:#5D6D7E;
$cloud-mid-color:#f98602;
$cloud-mid-color2:#f28d29;
$cloud-mid-color3:#fe8602 ;
.rocket {
    animation: rocketHovering 4s 3;
  }
  
  .jetstream {
    animation: streamHovering 4s 3;
    opacity: 1;
  }
  
  .cloud1 {
    animation: cloudMoving 3s infinite;
  }
  
  .cloud2 {
    animation: cloudMoving 4s infinite;
  }
  
  .cloud3 {
    animation: cloudMoving 3s infinite;
  }
  
  .cloud4 {
    animation: cloudMoving2 4s infinite;
  }
  
  .cloud5 {
    animation: cloudMoving 4s infinite;
  }
  
  .cloud6 {
    animation: cloudMoving 3s infinite;
  }
  
  .cloud7 {
    animation: cloudMoving2 3s infinite;
  }
  
  .cloud8 {
    animation: cloudMoving2 5s infinite;
  }
  
  .cloud9 {
    animation: cloudMoving2 7s infinite;
  }
  
  .cloud10 {
    animation: cloudMoving 4s infinite;
  }
  
  .cloud11 {
    animation: cloudMoving 6s infinite;
  }
  
  .lower-clouds {
    animation: lowerCloudMoving 5s infinite;
  }
  .stream-path {
      opacity:0;
    animation-name: fadeAway;
    animation-duration: 2s;
    animation-iteration-count: 1;
  }
  
  @keyframes rocketHovering {
    0% {
        fill:$rocket-mid-color;
      transform: translateY(0);
      
    }
  
  
    90% {
        fill:$rocket-mid-color;
        
    }
  
    100% {
        fill: $rocket-start-color;
        transform: translateY(-650px);
      }
  }
  
  @keyframes streamHovering {
    0% {
      fill:$stream-mid-color;
      transform: translateY(0);
    }
  
    48% {
        fill: $stream-start-color;
      transform: translateY(-1525px) scaleY(5.1);
    }
  
    100% {
      fill: $stream-start-color;
      transform: translateY(-1525px) scaleY(5.1);
    }
  }
  
  @keyframes cloudMoving {
    0% {
      transform: translateX(0);
    }
  
    50% {
      fill: $cloud-mid-color2;
      transform: translateX(5px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes lowerCloudMoving {
    0% {
      transform: translateX(0);
    }
  
    50% {
      fill: $cloud-mid-color3;
      transform: translateX(-4px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes cloudMoving2 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      fill: $cloud-mid-color;
      transform: translateY(6px);
    }
  
    100% {
      transform: translateY(0);
    }
  }

  @keyframes fadeAway {
    0% {
      opacity: 1;
    }
  
    50% {
        opacity: 0.5;
    }
  
    100% {
        opacity: 0;
    }
  }