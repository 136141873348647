.ForBiz {
    color: white;
    font-size: 0.75em;
    margin-left: -32%;
    padding-top: 15%;
}
.section-mvp-1 {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 50%, #ffffff 50%, #34495e 50%, #34495e 100%);
}
.section-mvp-3 {
    background: linear-gradient(to bottom, #34495e 0%,#34495e 50%,#34495e 50%,white 50%,white 100%);
}
.section-mvp-2 {
    background-color: #34495e;
}
.connector-mvp-1 {
    background: url(../../assets/images/mvp/connector-blue.svg) repeat-y top;
    height: 110px;
    left: 41.4%;
    top: 94%;
    width: 4px;
    position: absolute;

}
.connector-mvp-2 {
    background: url(../../assets/images/mvp/connector-blue.svg) repeat-y top;
    height: 110px;
    left: 41.4%;
    top: 94%;
    width: 4px;
    position: absolute;

}
.mvp-chip-one, .mvp-chip-two, .mvp-chip-three {
    position: relative;
    left: calc(50% - 32px);
    top: 17px;
    height: 64px;
    width: 64px;
    border: 1px solid #e6eaeb;
    font-weight: 700;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.15);
    font-size:1.5em;
}
.mvp-block-card {
    background-color: white;
    box-shadow: 0 21px 35px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #e6eaeb;
    text-align: left;
    transition: all 0.3s ease;
    padding: 5em; 
}

.mvp-block-title {
    text-align: center;
    font-size: 2em;
}
.mvp-block-text {
    text-align: center;
    font-size: 1.5em;
    padding-right: 2em;
    padding-left: 2em;    
}
.launchedRocket {
    position: absolute;
    z-index: 9999;

}
.mvp-cta-block {
    background-image: linear-gradient(83deg, #00c3c0, #0ad0d2);
    box-shadow: rgba(0, 0, 0, 0.37) 0px 4px 8px 0px;
    color: rgb(255, 255, 255);
    text-align: center;
    min-height: 180px;
    border-radius: 4px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 28px;
    -webkit-font-smoothing: antialiased;
    margin-bottom: -80px;
    z-index: 999;
}
.mvp-cta-block-home {
    background-image: linear-gradient(83deg, #00c3c0, #0ad0d2);
    box-shadow: rgba(0, 0, 0, 0.37) 0px 4px 8px 0px;
    color: rgb(255, 255, 255);
    text-align: center;
    min-height: 180px;
    border-radius: 4px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 28px;
    -webkit-font-smoothing: antialiased;
    margin-bottom: -125px;
    z-index: 999;
}
.mvp-cta-title
{
    color:white;
    margin-bottom:.75rem!important;
}
.mvp-cta-text{
    color:whitesmoke;
}
.mvp-cta-container {
    margin-top:40px
}
.mvp-cta-span {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 1rem;
}
.mvp-cta-container a {
    background-color:#34495e!important;
}
.mvp-founders-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .image.is-48x48 {
        height: 48px;
        width: 48px;
    }
    .mvp-founders-name{
        font-size: .75rem;
        color: #fff;
        padding: 0 10px;
    }
}
.mvp-stats-block {
    margin-left: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}.comments {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 5px;
}
.mvp-meta {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.mvp-stats-block {
    margin-left: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
// .cta-cursor {
//     --r: 0deg;
//     position: fixed;
//     left: -8px;
//     top: -6px;
//     pointer-events: none;
//     user-select: none;
//     display: none;
//     transform: translate(var(--x), var(--y));
//     filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.4));
// }
//     .svg {
//         display: block;
//         width: 28px;
//         height: 28px;
//         transform: rotate(var(--r));
//     }


.is-proxima-thin {
    font-family:'proxima_novathin';
}

@media (max-width: 768px) {
    .mvp-form-square {
        width:70px;
        height:70px;
        font-size: 11px;
    }
    .mvp-cta-container {
        padding-bottom: 100px;
    }
    .connector-mvp-1, .connector-mvp-2, .connector-mvp-3 {
        display: none;
    }
    .contentCard {
        margin-left:15px;
        
    }
    .mvp-block-text {
        text-align: center;
        font-size: 1em;
        padding-right: 1em;
        padding-left: 1em;
    }

}
@media (min-width: 768px) and (max-width: 1215px) {
    .step-text{
        font-size:12px;
    }
    .step-title{
        font-size:14px;
    }
    .featureCard{
        padding: 5px;
        min-height: 250px
    }
}
