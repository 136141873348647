/*! _team.scss || FoundPad  */

/* ==========================================================================
Team section styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Team Section
1. Advisors Section
=============================================================================
***/

/* ==========================================================================
0. Team Section
========================================================================== */

.team-member-container {
    position: relative;
    //Team member card
    .dark-card {
        width: 100%;
        border-radius: 1px;
        color: $white;
        transition: all .4s;
        padding: 20px 0;
        text-shadow: 0 2px 4px rgba(4,27,99,.25);
        background: rgba(17,149,247,.08);
        border: 1px solid rgba(0,83,251,.5);
        -webkit-box-shadow: 0 2px 3px 0 rgba(0,0,0,.5), 0 0 25px 0 rgba(18,60,255,.16);
        box-shadow: 0 2px 3px 0 rgba(0,0,0,.5), 0 0 25px 0 rgba(18,60,255,.16);
        position: relative;
        z-index: 2;
        //Avatar wrapper
        .avatar {
            text-align: center;
            position: relative;
            height: 140px;
            padding: 10px 0;
            //Svg circle wrapping image
            .circle-chart {
                transform: scale(0.92);
                position: absolute;
                top: calc(50% - 70px);
                left: calc(50% - 70px);
                .circle-chart-circle {
                    stroke: rgba(17,149,247,.0);
                    stroke-dasharray: 0, 100;
                    transition: all .2s linear;
                }
                .circle-chart-background {
                    stroke: rgba(17,149,247,.09);
                    border: 1px solid rgba(0,83,251,.5);
                }
            }
            //Member photo
            img {
                position: absolute;
                width: 100px;
                height: 100px;
                left: 0;
                right: 0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                transition: all .5s;
                //Real photo
                &.is-real {
                    opacity: 0;
                }
                //Sketch
                &.is-sketch {
                    opacity: 1;
                }
            }
        }
        //Hover state
        &:hover {
            background: rgba(17,149,247,.06);
            border: 1px solid rgba(0,83,251,.8);
            -webkit-box-shadow: 0 2px 3px 0 rgba(0,0,0,.5), 0 0 25px 0 rgba(18,60,255,.24);
            box-shadow: 0 2px 3px 0 rgba(0,0,0,.5), 0 0 25px 0 rgba(18,60,255,.24);
            //Circle hover state
            .avatar .circle-chart .circle-chart-circle{
                stroke-dasharray:  100, 100;
                stroke: $primary-accent;
            }
            //Member photo toggle
            img {
                &.is-real {
                    opacity: 1;
                }
                &.is-sketch {
                    opacity: 0;
                }
            }
        }
        //Member meta
        .member-info {
            text-align: center;
            //position in company
            .position {
                text-transform: uppercase;
                font-family: 'Open Sans', sans-serif;
                font-size: .75rem;
                color: $primary;
            }
            //Description
            .description {
                font-size: .8rem;
                padding: 10px 20px;
                color: rgba(94,147,255,.9);
            }
        }
    }
}

/* ==========================================================================
1. Advisors Section
========================================================================== */

//Container
.advisor-container {
    //image
    img {
        height: 70px;
        width: 70px;
        border-radius: 50%;
    }
    //Meta
    .info {
        .position {
            text-transform: uppercase;
            font-family: 'Open Sans', sans-serif;
            font-size: .75rem;
            color: $primary;
        }
    }
}