/** Radio control componenet */
.mvp-radio-control, .mvp-edit-control {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.mvp-form-radio {
    width:148px;
    height:148px;
    border-radius:50%;
    background-color:$primary;
    border: 2px solid #626f7a;
    color:white;
    margin:12px;
    text-align: center;
    vertical-align: middle;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    cursor:pointer;
    transition: all 0.5s ease-out !important;
    //font-size: 20px;
} 
.radio-control-selected{
background-color: #425c74;
border: 2px solid #00c3c0;    
}
.mvp-form-radio:hover {
    background-color: #425c74;
}
.radio-control-selected{
border: 2px solid #00c3c0;    
}
.radius-square {
    border-radius:4px;
    transition: all linear 0.3s;
}