/* | FoundPad  */
$ev-primary: #425e6b;
$ev-primary-accent: #e95e5a;
$ev-secondary: #607d8b;
$ev-secondary-accent: #45cbd2;
$ev-tertiary: #333;

$blueev: #e95e5a;

.ev-primary-gradient {
    background: $ev-primary;
    background: -webkit-linear-gradient(to right, $ev-primary, $ev-primary-accent) !important;
    background: linear-gradient(to right, $ev-primary, $ev-primary-accent) !important; 
}

.ev-secondary-gradient {
    background: $ev-secondary;
    background: -webkit-linear-gradient(to right, $ev-secondary, $ev-secondary-accent) !important;
    background: linear-gradient(to right, $ev-secondary, $ev-secondary-accent) !important; 
}

.bg-ev {
    background-color: $ev-primary!important;
    color:#fff;
}
.hero-img-ev {
    width: 50%!important;
    float: right;
}
.logo-block
{
    padding-top: 1px;
}
.ev-bg-img 
{
    background-image: url('../images/f619/f619-bg2.jpg');
    background-size: cover;
    margin-bottom: -95px;
}
.step-img
{
    width: 64px;
    height: 64px;
}
.ev-divider{height: 3px;
    height: 3px;
    width: 75px;
    background: #27c3c0;
    // background: -webkit-linear-gradient(to left, $ev-secondary-accent, $ev-primary) !important;
    // background: linear-gradient(to left, $ev-secondary-accent, $ev-primary) !important;
    margin: 20px 0;
    //Centered modifier
    &.is-centered {
        margin: 20px auto;
    }
    //Long modifier
    &.is-long {
        width: 150px;
    }
    //Acts as a spacing element
    &.is-spacer {
        margin-top: 120px;
    }
    
}
.is-evbutton {
    background-color:$ev-primary-accent;
    transition: all 0.6s ease-out !important;
    border: none;
}
.is-f619button {
    background-color:$ev-secondary-accent;
    transition: all 0.6s ease-out !important;
    border: none;
}
.dark-title {
    color:$ev-primary!important;
    &:active {
        background-color: #2a927e2b;
    }
}
.ev-next 
{
    background: url(../images/icons/chevron-right.svg) $ev-primary no-repeat 0 0!important;
}
.ev-prev 
{
    background: url(../images/icons/chevron-left.svg) $ev-primary no-repeat 0 0!important;
}

.ev-timeline-a{
    background-color: #425e6b40 !important;
    color: $ev-primary!important; 
    transition: 0.3s linear;
    &:active {
        //ever visible, this is used in jQuery to check the current media screen
        background-color: #425e6b!important;
        color: #fff!important; 
    }
}
.ev-events-content li{
    background-color: $ev-primary-accent !important;
}
.ev-filling {
    background: -webkit-linear-gradient(to right, $ev-secondary, $ev-secondary-accent) !important;
    background: linear-gradient(to right, $ev-secondary, $ev-secondary-accent) !important;
}
.ev-timeline {
    opacity: 0;
    margin: 0 auto;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
    &:before {
        //ever visible, this is used in jQuery to check the current media screen
        content: 'mobile';
        display: none; 
    }
    &.loaded {
        // show the timeline after events position has been set (using JavaScript)
        opacity: 1;
    }
    .timeline {
        position: relative;
        height: 100px;
        width: 90%;
        max-width: 800px;
        margin: 0 auto;
    }
    //Controls
    .events-wrapper {
        position: relative;
        height: 100%;
        margin: 0 40px;
        overflow: hidden;
        &:after, &:before {
            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            height: 100%;
            width: 20px;
        }
        &:before {
            left: 0;
        }
        &:after {
            right: 0;
        }
    }
    .events {
        //Grey line/timeline 
        position: absolute;
        z-index: 1;
        left: 0;
        top: 49px;
        height: 2px;
        //Width is set using JavaScript
        background: #dfdfdf;
        -webkit-transition: -webkit-transform 0.4s;
        -moz-transition: -moz-transform 0.4s;
        transition: transform 0.4s;
        a {
            position: absolute;
            width: 55px;
            bottom: 0;
            z-index: 2;
            text-align: center;
            font-size: .75rem;
            font-family: 'Cabin', sans-serif;
            padding-bottom: 15px;
            //Fix for issue on Safari - text flickering while timeline translates
            -webkit-transform: translateZ(0);
            -moz-transform: translateZ(0);
            -ms-transform: translateZ(0);
            -o-transform: translateZ(0);
            transform: translateZ(0);
            background: $primary;
            
            color: $white;
            border-radius: 3px;
            padding:5px;
            margin-bottom:20px;
            &:before {
                content: '';
                position: absolute;
                width:1px;
                height:20px;
                background: $ev-primary-accent;
                
                bottom:-20px;
                left:50%;
            }
            &:after {
                //Event dot
                content: '';
                position: absolute;
                left: 50%;
                right: auto;
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
                bottom: -25px;
                height: 12px;
                width: 12px;
                border-radius: 50%;
                border: 2px solid #dfdfdf;
                background-color: #f8f8f8;
                -webkit-transition: background-color 0.3s, border-color 0.3s;
                -moz-transition: background-color 0.3s, border-color 0.3s;
                transition: background-color 0.3s, border-color 0.3s;
            }
            //Selected state
            &.selected {
                pointer-events: none;
                background-color: $ev-primary!important;
                color: #fff!important;
                &:after {
                    background: $secondary;
                    background: -webkit-linear-gradient(to right, $ev-secondary, $ev-secondary-accent) !important;
                    background: linear-gradient(to right, $ev-secondary, $ev-secondary-accent) !important; 
                    border-color: $ev-primary;
                }
            }
            //Old state
            &.older-event {
                &:after {
                    border-color: $secondary;
                }
            }
        }
    }
    .filling-line {
        //Green line filling the timeline 
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
         
        -webkit-transform: scaleX(0);
        -moz-transform: scaleX(0);
        -ms-transform: scaleX(0);
        -o-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -ms-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
        -webkit-transition: -webkit-transform 0.3s;
        -moz-transition: -moz-transform 0.3s;
        transition: transform 0.3s;
    }
}
.ev-avatar-img {
    position: absolute;
    width: 175px!important;
    height: 175px!important;
    left: 0;
    right: 0;
    top: 49%!important;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    transition: all 0.5s;
}
.ev-stroke
{    stroke: #29cff0!important;}

.perk-card-container {
    position: relative;
    //Team member card
.ev-card {
    width: 100%;
    border-radius: 1px;
    color: $white;
    transition: all .4s;
    padding: 20px 0;
    text-shadow: 0 2px 4px rgba(4,27,99,.25);
    background: rgba(17,149,247,.08);
    border: 1px solid rgba(0,83,251,.5);
    -webkit-box-shadow: 0 2px 3px 0 rgba(0,0,0,.5), 0 0 25px 0 rgba(18,60,255,.16);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,.5), 0 0 25px 0 rgba(18,60,255,.16);
    position: relative;
    z-index: 2;
    //Avatar wrapper
    .avatar {
        text-align:center;
        position: relative;
        height: 140px;
        padding: 10px 0;
        //Svg circle wrapping image
        .circle-chart {
            transform: scale(0.92);
            position: absolute;
            top: calc(50% - 70px);
            left: calc(50% - 70px);
            .circle-chart-circle {
                stroke: rgba(17,149,247,.0);
                stroke-dasharray: 0, 100;
                transition: all .2s linear;
            }
            .circle-chart-background {
                stroke: rgba(163, 177, 183, 0.2);
                border: 1px solid rgba(0,83,251,.5);
            }
        }
        //Member photo
        img {
            position: absolute;
            width: 100px;
            height: 100px;
            left: 0;
            right: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            transition: all .5s;
            //Real photo
            &.is-real {
                opacity: 0;
            }
            //Sketch
            &.is-sketch {
                opacity: 1;
            }
        }
    }
    //Hover state
    &:hover {
        background: rgba(17,149,247,.06);
        border: 1px solid rgba(0,83,251,.8);
        -webkit-box-shadow: 0 2px 3px 0 rgba(0,0,0,.5), 0 0 25px 0 rgba(18,60,255,.24);
        box-shadow: 0 2px 3px 0 rgba(0,0,0,.5), 0 0 25px 0 rgba(18,60,255,.24);
        //Circle hover state
        .avatar .circle-chart .circle-chart-circle{
            stroke-dasharray:  100, 100;
            stroke: $ev-secondary-accent;
        }
        //Member photo toggle
        img {
            &.is-real {
                opacity: 1;
            }
            &.is-sketch {
                opacity: 0;
            }
        }
    }
    //Member meta
    .member-info {
        text-align: center;
        //position in company
        .position {
            text-transform: uppercase;
            font-family: 'Open Sans', sans-serif;
            font-size: .75rem;
            color: $primary;
        }
        //Description
        .description {
            font-size: .8rem;
            padding: 10px 20px;
            color: rgba(94,147,255,.9);
        }
    }
}
}
.ev-logos {height: 50px;
           margin-right:12.5px; }
.ev-content-card
{
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.2)!important;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding: 30px!important;
}
.dark-text {
    h4 {
    padding-left: 10px;
    border-left: 1px solid #e95e5a;
    }
}
.ev-card-title {
    padding-left: 10px;
    border-left: 1px solid #e95e5a;
}
.ev-via{
    font-size: 0.6em;
    padding-bottom: 10px;
    text-transform: lowercase;
    right: 20%;
    position: absolute;
}
.ev-icon-block
{
    background-image: url(../images/ev2019/foundpad.png);
    background-size: 29%;
    min-height: 30px;
    background-repeat: no-repeat;
    background-position: 85% center;
}
/**************** f619 style *************/
/**************** f619 style *************/
.f619-divider{height: 3px;
    height: 3px;
    width: 75px;
    background: $ev-primary-accent;
    // background: -webkit-linear-gradient(to left, $ev-secondary-accent, $ev-primary) !important;
    // background: linear-gradient(to left, $ev-secondary-accent, $ev-primary) !important;
    margin: 20px 0;
    //Centered modifier
    &.is-centered {
        margin: 20px auto;
    }
    //Long modifier
    &.is-long {
        width: 150px;
    }
    //Acts as a spacing element
    &.is-spacer {
        margin-top: 120px;
    }
    
}
.bg-f619
{background-color: $ev-primary-accent!important;}