.dark-modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #34495ef2;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
   
    z-index: 2;
   
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    
    overflow-y: auto;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex: 1 1 0%;
    transition : all linear .3s;
}
.dark-modal-wrapper.me-hidden {
    display:none!important;
}
.dark-modal-box {
width: 450px;
background-color:$primary;
transition : all ease-out .3s;
color:white;
font-family:"proxima_nova_altlight";
font-size:18px;
box-shadow: 0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05);
border-radius:.6em;
position: relative;
display: flex;
-webkit-box-align: center;
align-items: center;
flex-direction: column;
padding: 26px 28px;
}
.dark-modal-content {

}
.dark-modal-social {

}
.dark-modal-logo {

}
.dark-modal-bar
{

}
.dark-modal-footer
{
    width: 350px;
    border-top: 1px solid #8595a552;
}
.modal-cta-wrapper {
    width: 350px;
    margin: 0 auto; 
}
.dark-modal-close {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    position: absolute;
    top: 22px;
    right: 22px;
    cursor: pointer;
    &:hover {
        transform:scale(1.5);
        transition: all linear .3s;
    }
}
.dark-modal-icon {
    background-size: cover;
    border-radius: 50%;
    transition: background-image linear 0.3s;
}
.modal-cta-icon {
    height:16px;
    width:16px;
}
.cta-prefix {
   
    display: inline-flex;
    align-items: center;
    padding-right: 15px;
}
.dark-modal-title {
color:#fff;
font-weight:600;
font-size:1.5em;
font-family:'Exo 2';
text-align: center;
}
.dark-modal-subtitle {
    color:$lightgray;
    font-weight:100;
    font-size:1.15em;
    text-align: center;
      
}
.pt-15 {
    padding-top:15px;
}
.mb-15{
    margin-bottom:15px;
}
.mb-25{
    margin-bottom:25px;
}
.modal-cta {
    transition: all 0.2s ease-out !important;
    border: none;
    box-shadow: 0 1px 2px -10px rgba(0, 0, 0, 0.42), 0 1px 1px 0px rgba(0, 0, 0, 0.12), 0 2px 2px -1px rgba(0, 0, 0, 0.2) !important;
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 100;
    background-color: #00c3c0;
    color: white;
    text-align:center;
    cursor: pointer;
    font-family: "Exo 2" !important;
    opacity: 1;
    padding : 16px 18px;
    border-radius:4px;
    &:hover {
        opacity:.9;
    }
}
.dark-modal-footer .modal-cta {
    background-color:$secondary;
    margin-bottom:25px;
}