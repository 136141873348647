
.dna-hex-1 {
    box-shadow: 0 0 20px rgba(0,195,192,0.85);
    background-image: url(../../assets/images/mvp/mindset-0.svg);
    background-size: 58px 77px;
    background-blend-mode: color;
    background-color: #00c3c0cc;

}
.dna-hex-1 .hexTop, .dna-hex-1 .hexBottom {
    box-shadow: 0 0 40px rgba(0,195,192,0.85);
}
.dna-hex-2 {
    box-shadow: 0 0 20px rgba(246, 134, 5, 0.85);
    background-image: url(../../assets/images/mvp/mvp-focus-0.svg);
    background-size: 54px 73px;
    background-blend-mode: color;
    background-color:#ff8602b3;
}
.dna-hex-2 .hexTop, .dna-hex-2 .hexBottom {
    box-shadow: 0 0 40px rgba(246, 134, 5, 0.65);
}
.dna-hex-3 {
    box-shadow: 0 0 20px rgba(255, 101, 132, 0.85);
    background-image: url(../../assets/images/mvp/tech-stack-0.svg);
    background-blend-mode: color;
    background-color: #ff6584ba;
    background-size: 58px 73px;
}
.dna-hex-3 .hexTop, .dna-hex-3 .hexBottom {
    box-shadow: 0 0 40px rgba(255, 101, 132, 0.85);
}
.dna-bg-1 {
  -webkit-transition: background-image 1s linear;
  transition: background-image 1s linear;  
  background-image: url(../../assets/images/mvp/mindset.svg);

}
.dna-bg-2 {
  -webkit-transition: background-image 1s linear;
  transition: background-image 1s linear;  
  background-image: url(../../assets/images/mvp/mvp-focus.svg);

}
.dna-bg-3 {
  -webkit-transition: background-image 1s linear;
  transition: background-image 1s linear;  
  background-image: url(../../assets/images/mvp/tech-stack.svg);
  
}
.hexagon {
    position: relative;
    width: 64px; 
    height: 36.95px;
    margin: 18.48px 0;
    background-position: center;
     }
 .dna-hover-1, .dna-hover-2, .dna-hover-3 {
  cursor:pointer;
 }
  .hexTop,
  .hexBottom {
    position: absolute;
    z-index: 1;
    width: 45.25px;
    height: 45.25px;
    overflow: hidden;
    -webkit-transform: scaleY(0.5774) rotate(-45deg);
    -ms-transform: scaleY(0.5774) rotate(-45deg);
    transform: scaleY(0.5774) rotate(-45deg);
    background: inherit;
    left: 9.37px;
   
  }
  
  /*counter transform the bg image on the caps*/
  .hexTop:after,
  .hexBottom:after {
    content: "";
    position: absolute;
    width: 64.0000px;
    height: 36.950417228136054px;
    -webkit-transform:  rotate(45deg) scaleY(1.7321) translateY(-18.4752px);
    -ms-transform:      rotate(45deg) scaleY(1.7321) translateY(-18.4752px);
    transform:          rotate(45deg) scaleY(1.7321) translateY(-18.4752px);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    background: inherit;
  }
  
  .hexTop {
    top: -22.6274px;
  }
  
  .hexTop:after {
    background-position: center top;
  }
  
  .hexBottom {
    bottom: -22.6274px;
  }
  
  .hexBottom:after {
    background-position: center bottom;
  }
  
  // .hexagon:after {
  //   content: "";
  //   position: absolute;
  //   top: 0.0000px;
  //   left: 0;
  //   width: 64.0000px;
  //   height: 36.9504px;
  //   z-index: 2;
  //   background: inherit;
  // }    background-size: auto 73.9008px;
   