/*! _faq.scss | FoundPad  */

/* ==========================================================================
FAQ section styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Accordion
=============================================================================
***/

/* ==========================================================================
0. Accordion
========================================================================== */
$primary: #34495e;
$primary-accent: #00c3a0;
$secondary: #02213b;
$secondary-accent: #ff8602;
$tertiary: #333;
$ev-primary: #425e6b;
$ev-primary-accent: #e95e5a;
$ev-secondary: #607d8b;
$ev-secondary-accent: #45cbd2;
$ev-tertiary: #333;
.accordion {
    margin: 3em auto;
    max-width: 30em;
    //toggle section
    .accordion-toggle {
        display: none;
    }
    //Clickable block
    .accordion-option {
        position: relative;
        margin-bottom: 1em;
        background: $ev-primary-accent;
        //border: 1px solid rgba(0,83,251,.5);
        -webkit-box-shadow: 0 2px 3px 0 rgba(0,0,0,.5), 0 0 25px 0 rgba(18,60,255,.16);
        box-shadow: 0 2px 3px 0 rgba(0,0,0,.5), 0 0 25px 0 rgba(18,60,255,.16);
        border-radius: 4px;
        .accordion-title,
        .accordion-content {
            backface-visibility: hidden;
            transform: translateZ(0);
            transition: all 0.4s;
        }
        //Title
        .accordion-title {
            padding: 1em;
            display: block;
            color: #fff;
            font-family: Dosis;
            font-weight: 600;
            font-size: 0.9rem;
            cursor: pointer;
        }
        .accordion-title:after, .accordion-title:before {
            content: '';
            position: absolute;
            right: 1.25em;
            top: 1.25em;
            width: 2px;
            height: 0.75em;
            // @include secondary-gradient-left();
            background-color: $ev-primary;
            transition: all 0.2s;
        }
        .accordion-title:after {
            transform: rotate(90deg);
        }
        //Content
        .accordion-content {
            max-height: 0;
            overflow: hidden;
            p {
                margin: 0;
                padding: 0.5em 1em 1em;
                font-size: 0.9em;
                line-height: 1.5;
                color: #fff;
            }
        }
        //Behaviour
        .accordion-toggle:checked {
            & + .accordion-title + .accordion-content {
                max-height: 500px;
            }
            & + .accordion-title:before {
                transform: rotate(90deg) !important;
            }
        }
    }
}

