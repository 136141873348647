$primary: #34495e;
$primary-accent: #00c3c0;
$secondary: #2b3c4c;
$secondary-accent: #ff8602;
$tertiary: #3b5369;
$label-color : #ffffff9e;
$text-color : #b1b7bd;
$lighter : #ffffff73;
$line-color: #314559;

.dark-app-bg {
    background-color: $secondary;
}
.dark-bar {
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    background-color: $secondary!important;
}
.ev-content-card, .dark-from-card {

    background-color: $primary;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0,0,0,.2)!important;
    -webkit-box-shadow: 0px 1px 3px rgba(0,0,0,.2)!important;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    padding: 30px 35px!important;

}
.dark-appbar-rounded {
    border-radius: 25%!important;
    height: 32px!important;
    width: 32px!important;
    max-height: 32px!important;
    box-shadow: none;
}
.appbar-text-light {
    color:#b1b7bd!important;
}
.appbar-drop-svg-light {
  
    stroke: $label-color;
    width: 12px;
    height: 12px;
    stroke-width: 1.4px;
    cursor: pointer;
    transition: all 0.3s;
  
}
.appbar-svg-light {
    width: 24px;
    height: 24px;
    fill: none;
    stroke: $label-color;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
}
.appbar-drop-a-light, .tab-title-light {
   
    color:$label-color!important;
}
.primary-text-light
{
    color: $lighter!important;

}
.columns-leftless {
    margin-left:0!important;
}
.tab-img {
    height: auto!important;
}
.dark-flying-tabs {
    background: $primary;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2)!important;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.dark-flying-tabs-container {
    max-width: unset;
}
.dark-dropdown-menu {
    display: none;
    position: absolute!important;
    z-index: 20!important;
    width: 7em!important;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
}
.dark-dropdown-content {
    background-color: $primary!important;
}
.mt-15 {
    margin-top:15px
}