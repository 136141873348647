.mvp-svg-msg-wrapper {
width:64px;
height:64px;
display:flex;
margin : 0 auto;
}
.mvp-svg-msg {
width:100%;
height:150px;
}
.fragment {
    transition : all ease-in-out .5s;
}
.fragment-shatter {
    transform : translate(0px, 0px)!important;
}
.fragment-1 {
    transform: translate(30px, 19px);
}
.fragment-2 {
    transform: translate(-103px, 19px);
}
.fragment-3 {
    transform: translate(30px, -40px);
}
.fragment-4 {
   transform: translate(-34px, -40px) 
}
.fragment-5 {
    transform: translate(-104px, -40px);
}