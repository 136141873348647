.is-valigned {
    margin-top:60px;
}
.is-90x90 {
    width: 90px;
    height: 90px;
}
.is-150x150 {
    width: 150px;
    height: 150px;
}
.mvp-bar {

}
.bar-centered {
    margin : 0 auto;
}
.founder-img, .loader-team-img {
    background-size: cover;
    border-radius: 50%;
    background-image:none;
    transition: background-image linear .3s;
    //box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.48);
    border: 2px solid #626f7a;
}
.ceo {
background-image: url(../../assets/images/team/mehdi-300.png);
}
.cto {
background-image: url(../../assets/images/team/hazem-300.png);
}
.hiba {
background-image: url(../../assets/images/team/hiba-300.png); 
}
.logo {
background-image: url(../../assets/images/icons/Untitled.svg);
border:none;
}
.mvp-bar.is-bordered {
    border: 1px solid #626f7a;
}
.mvp-form-progress {
    border-radius: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100;
    margin-bottom:0px!important;
   // transition : all 0.3s ease;
}
progress::-moz-progress-bar { 
                            background-color: $primary-accent!important;
                            transition : all 0.5s ease; }
progress::-webkit-progress-value { 
                                background-color: $primary-accent!important;
                                transition : all 0.5s ease; }
progress {  
            color: $primary-accent!important;
            transition : all 0.5s ease; }

.is-16x16 {
    width: 16px;
    height: 16px;
}
.pl-0{
    padding-left: 0px!important;
}
.pr-0{
    padding-right: 0px!important;
}
.item-prefix{
    height: 40px;
    display: flex;
    align-items: center;
    padding-right: 5px;
}
.item-suffix {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 5px;
}
.phone-call-cta {
    padding: 26px 28px !important;
    display:none;
}