/* FoundPad Modal  */
$primary: #34495e;
$primary-accent: #00c3a0;
$secondary: #02213b;
$secondary-accent: #ff8602;
$tertiary: #333;
$ev-primary: #425e6b;
$ev-primary-accent: #e95e5a;
$ev-secondary: #607d8b;
$ev-secondary-accent: #45cbd2;
$ev-tertiary: #333;


.fmodal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    //z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: #425e6b; /* Fallback color */
    background-color: #425e6bde; /* Black w/ opacity */
    z-index: 99998;
  }
  
  /* Modal Content */
  .fmodal-content {
    position: relative;
    background-color: $ev-primary;
    margin: auto;
    padding: 0;
    border-radius: 6px;
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: gelatine;
    -webkit-animation-duration: 0.4s;
    animation-name: gelatine;
    animation-duration: 0.4s;
    transition: all 2s ease;
  }
  
  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {top:-40%; opacity:0} 
    to {top:0; opacity:1}
  }
  
  @keyframes animatetop {
    from {top:-40%; opacity:0}
    to {top:0; opacity:1}
  }
  
  /* The Close Button */
  .fclose {
    color: $ev-secondary-accent;
    position: relative;
    float: right;
    font-size: 28px;
    font-weight: bold;
    z-index: 99999;
    padding-right: 20px;
  }
  
  .fclose:hover,
  .fclose:focus {
    color: $ev-secondary-accent;
    opacity: 0.8;
    cursor: pointer;
  }
  
  .fmodal-header {
    padding: 2px 16px;
    background-color: $ev-primary-accent;
    color: white;
  }
  
  .fmodal-body {padding: 2px 16px;}
  
  .fmodal-footer {
    padding: 2px 16px;
    background-color: $ev-primary-accent;
    color: white;
  }