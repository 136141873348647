/** MVP Form Input Compoement */
.max-min-100vh {
    max-height: 100vh;
    min-height: 100vh;
}
.mvp-form-input {
    -webkit-appearance: none;
    align-items: center;
    border-radius: 1px;
    display: block;
    margin:0 auto;
    font-size: 1.75rem;
    height: 3em;
    justify-content: flex-start;
    line-height: 1.5;
    padding: calc(.375em - 1px) calc(.625em - 1px);
    position: relative;
    vertical-align: top;
    
    color: black;
    max-width: 100%;
    width: 50%;
    transition: transform 0.15s;
    transform: scale(1);
    border: none !important;
    box-shadow: none;
    border-color: white;
    box-shadow: 0 0 0 0.08em rgba(255, 255, 255, 0.25);
    transition: all 0.5s;
    
&.dark {
    background-color: #34495e80;
    border-color: #34495da3;
    color: #b1b7bd;
    }
}
.mvp-form-input.dark:focus, .mvp-form-input.dark:active {
    background-color: #34495e;
    box-shadow: none;
    transform: scale(1.02);
    transition: all 0.3s;
}
.mvp-form-input.dark:hover {
    border-color: #34495da3;
    box-shadow: 0 0 0 0.125em #00c3c0;
    transition: all 0.5s;
    background-color: #34495e;
}

/**Question / Label Component */
.mvp-form-subtitle {
    color: #b1b7bd;
    font-weight: 400;
    line-height: 1.25;
    max-width: 600px;
    margin: 0 auto;
    font-size: 1em;
}
.question {
    max-width: 700px;
    margin: auto;
    color:white!important;
}


/** Next Button Component */

.mvp-form-next-button {    
    margin: 75px auto auto auto;
    display: block;
    font-family: 'Exo 2';
    color: $text-color;
    font-weight: 500 !important;
    text-transform: uppercase;
    border-width: 2px;
    min-width: 130px;
    font-size: 1.15rem;
    height: 3em;
    width:200px;
    background-color: #34495e;
    transition: all 0.2s ease-out !important;
    border: none;
    box-shadow: 0 1px 2px -10px rgba(0, 0, 0, 0.42), 0 1px 1px 0px rgba(0, 0, 0, 0.12), 0 2px 2px -1px rgba(0, 0, 0, 0.2) !important;
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    padding-top: calc(0.375em - 1px);
    cursor: pointer;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    
    border-radius: 3px;
    line-height: 1.5;
    align-items: center;
}
.mvp-form-next-button:hover {
    //box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
    background-color: #00c3c0;
    color:white;
    
   
}
.mvp-form-next-button:after {
  content: '›';
  position: absolute;
  opacity: 0;  
  top: 0.5rem;
  left: 61%;
  transition: 0.3s;
  font-size: 1.5rem;
}
.mvp-form-next-button:hover:after {
  opacity: 1;
  left: 55%;
}
.mvp-form-next-button:disabled{
    background-color: #8c919600;
    color: #8c91965c;
    cursor: not-allowed;
    z-index: -1;
    opacity: 0.2;
    transition: all linear 0.3s;
}
.mvp-next-button-alternative{
    margin:0!important;
    display: flex!important;
    
}
.mvp-button-alternative{
    margin-top: 0;
    background: none;
    box-shadow: none!important;
    display: flex;
    font-family: "Exo 2";
    color: #b1b7bd;
    font-weight: 500 !important;
    text-transform: uppercase;
    border-width: 2px;
    min-width: 130px;
    font-size: 1.15rem;
    height: 3em;
    width: 200px;
    transition: all 0.2s ease-out !important;
    border: none;
    padding-bottom: calc(0.375em - 1px);
    padding-left: calc(0.625em - 1px);
    padding-right: calc(0.625em - 1px);
    padding-top: calc(0.375em - 1px);
    cursor: pointer;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    border-radius: 3px;
    line-height: 1.5;
    align-items: center;
    
    &:hover{
    color:white;}
    }
.or-text {
    display: block;
    color: white;
    text-align: center;
    margin: 0 auto;
    padding-top: 15px;
}
/** Prev button component */
.mvp-prev {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 999;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    width: 64px;
    height: 64px;
    background-color: #2f4255;
    border-radius: 50%;
    font-size: 0px;
    -webkit-box-shadow: 0 1px 2px -10px rgba(0, 0, 0, 0.42), 0 1px 1px 0px rgba(0, 0, 0, 0.12), 0 2px 2px -1px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 1px 2px -10px rgba(0, 0, 0, 0.42), 0 1px 1px 0px rgba(0, 0, 0, 0.12), 0 2px 2px -1px rgba(0, 0, 0, 0.2) !important;
    text-align: center;
    vertical-align: middle;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-out !important;
    color: #b1b7bd;
}
.mvp-prev:hover {
    background-color: #00c3c0;
    color: white;
}
.prev-icon{
    
}


.alternative-link {
    margin: 75px auto auto auto;
    display: block;
    font-family: "Exo 2";
    color: #b1b7bd;
    font-weight: 500 !important;
    text-transform: uppercase;
    border-width: 2px;
    min-width: 130px;
    font-size: 1.15rem;
    height: 3em;
    width: 200px;
}
/** Content Inline Editor */
.mvp-form-edit, .mvp-form-static {
    min-width:350px;
    min-height:148px;
    //border-radius:50%;
    //background-color:$primary;
    //border: 2px solid #626f7a;
    color:white;
    margin:12px;
    text-align: center;
    vertical-align: middle;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    //cursor:pointer;
    transition: all 0.5s ease-out !important;
    border-radius:6px;
    //font-size: 20px;
} 
.mvp-form-edit:hover{
    background-color: #34495e;
    cursor: pointer;
}
.startup-description {
	position: relative;
	padding-left: 30px;
	font-weight: 100;
	color: white;
	padding: 1em;
	margin: 1em;
	max-width: 33em;
    font-size: 28px;
    font-style:normal;
	&:before {
		content: "“";
		font-family: serif;
		position: absolute;
		right: 100%;
		font-size: 120px;
		line-height: 0px;
		top: 50px;
        color: $pinkiary;
       
	}&:after {
		content: url(../../assets/images/icons/clicky.gif);
        display: block;
        position: absolute;
        top: 70%;
        width: 0px;
        height: 0px;
        left: 87%;
        transform: scale(.25);
    }
}
.lead-description {
	position: relative;
	padding-left: 30px;
	font-weight: 100;
	color: white;
	padding: 1em;
	margin: 1em;
	max-width: 33em;
    font-size: 28px;
    font-style:normal;
	&:before {
		content: "“";
		font-family: serif;
		position: absolute;
		right: 100%;
		font-size: 120px;
		line-height: 0px;
		top: 50px;
        color: $pinkiary;
       
	}&:after {
		content: '';
		display: block;
		position: absolute;
		top: 100%;
		width: 80px;
		height: 5px;
		background: #00c3c0;
		left: 1em;
	}
}

.startup-name,
.startup-solution,
.startup-users,
.users-benefit,
.product-core,
{}

.startup-name{
    font-weight: 600;
}
.startup-solution{
    font-style:italic;
    border-bottom:1px dashed $primary-accent;
    
}
.edit-cursor {cursor: url(../../assets/images/icons/edit-icon.svg), auto;}
.startup-users{
    font-style:italic;
    border-bottom:1px dashed $pinkiary;
    
}
.edit-cursor-pink {cursor: url(../../assets/images/icons/edit-pink.svg), auto;}
.users-benefit{
    
}
.product-core{
    font-style:italic;
    border-bottom:1px dashed $secondary-accent;
    
}
.edit-cursor-orange {cursor: url(../../assets/images/icons/edit-orange.svg), auto;}
.description-meta {
    display: inline-flex;
    text-align: right;
    padding-right: 150px;
    color: white;
    float: right;
}
.lead-description {
    text-align:left;
}
.legend-solution, .legend-users, .legend-core {
    display: inline-flex;
    min-width: 130px;
    width: 130px;
}
.legend-square-solution {
    height: 16px;
    width: 16px;
    background-color: $primary-accent;
    display: inline-flex;
}
.legend-square-users {
    height: 16px;
    width: 16px;
    background-color: $pinkiary;
    display: inline-flex;
}
.legend-square-core {
    height: 16px;
    width: 16px;
    background-color: $secondary-accent;
    display: inline-flex;
}
.legend-text {
    margin-left: 16px;
    margin-top: -2px;
}
.startupProblem{
    resize:none;
    min-height:120px;
}
.question-icon {
    color: white;
    align-self: center;
    width: 100%;
}
/** JS events classes */
.false-0 {}
.true-0 {}
.step-hidden, .tooltip-hidden, .me-hidden {
    opacity:0;
    z-index:-999;
}
.step-removed, .tooltip-removed, .me-removed {
    display:none;
}
.step-22-show-1, .step-22-show-2, .step-22-show-3, .step-5-show-1, .step-5-show-2, .step-5-show-3, .step-5-show-4, .step-0-show-1, .step-0-show-2, .step-0-show-3, .step-12-show-4, .step-12-show-2, .step-12-show-3, .step-12-show-4 {
    transition : all linear 0.3s;
}
.step-active, .tooltip-active, .me-active {
    display:block;
    opacity:1;
    z-index:2;
}
.prev-active {
    display:flex;
    opacity:1;
    z-index:2;
}
.mvp-form-step{
  transition: all 0.5s ease-out !important;
  
  
}
.mvp-form-tooltip{
    transition: all 0.5s ease-out !important;
}
.tooltip-step0-yes {

}
.tooltip-step0-no {   
}



/*** QUICK MODE FORM  */
.quick-step-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    
}

.quick-step-form input {
  
    
}
.quick-step-form #detailsForm {
padding:20px;
background-color: $primary;
border-radius: 6px;
}
.text-right{
    text-align: right;
}
.quickProduct{
    height: 50px;
    font-size: 14px;
}
.mt-5 {
    margin-top:5px;
}

/** Disabled / Enabled */
.is-disabled
{
    background-color: #8c919600;
    color: #8c91965c;
    cursor: not-allowed;
    z-index: -1;
    opacity: 0.2;
    transition: all linear .3s;
}
.is-enabled {
    opacity:1;
    transition: all linear .3s;
}
