/*! _responsive.scss | | FoundPad  */

/* ==========================================================================
Responsive styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Tablet Landscape
1. Tablet Portrait
2. Mobile
3. Mixed Mobile / Tablet Portrait
=============================================================================
***/

/* ==========================================================================
0. Tablet Landscape
========================================================================== */

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {

}

/* ==========================================================================
1. Tablet Portrait
========================================================================== */

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    .landing-caption {
        padding: 0 10%;
        .divider {
            margin: 0 auto;
        }
        h1, h2, p {
            text-align: center !important;
        }
        h1 {
            padding: 0 10%;
        }
    }
    //features
    .feature p {
        padding: 0 25%;
    }
    //Side features
    .side-feature-content {
        text-align: center;
        img {
            display: block;
            margin: 10px auto;
        }
        .divider {
            margin: 0 auto;
        }
        p {
            padding: 0 12%;
        }
    }
    //tabs
    .flying-wrapper {
        ul {
            .item-content {
                font-size: 80% !important;
            }
        }
    }
    //team
    .team-member-container {
        margin: 0 25%;
    }
}

/* ==========================================================================
2. Mobile
========================================================================== */

@media (max-width: 767px) {
    //Navbar
    .navbar {
        .navbar-item {
            &.is-nav-link {
                text-align: center !important;
            }
        }
        .button.slanted {
            width: 100% !important;
        }
        .navbar-menu.is-active {
            &.light-menu {
                background: $primary !important;
            }
        }
        &.is-light.is-dark-mobile {
            background: $primary !important;
        }
    }
    //Landing page hero
    .landing-caption {
        .divider {
            margin: 0 auto;
        }
        h1, h2, p {
            text-align: center !important;
        }
        h1 {
            font-size: 1.05rem !important;
        }
        h2 {
            font-size: 1rem !important;
        }
    }
    //features
    .side-feature-content {
        text-align: center;
        img {
            display: block;
            margin: 10px auto;
        }
        .divider {
            margin: 0 auto;
        }
    }
    //tabs
    .flying-wrapper {
        ul {
            a {
                width: 23.8% !important;
            }
            .item-content {
                font-size: 80% !important;
            }
        }
    }
    .ico-card {
        padding: 20px 30px !important;
    }
    //events
    .events-content {
        min-height: 386px;
    }
    //Roadmap bottom image
    .roadmap-end {
        left: -100%;
        transform: translateX(210px);
        top: -86px;
    }
    .lucid-text {
        font-size:1em!important;
    }
    .title.is-1.landing-step {
        width: 100%!important;
        font-size: 2rem!important;
        text-align: center!important;
    }
    .mvp-block-card {
        padding: 30px 35px!important;
    }
}


/* ==========================================================================
3. Mixed Mobile / Tablet Portrait
========================================================================== */

@media (min-width: 1700px) {
    .launchedRocket {
        top: calc(-160% - 37px);
        left: calc(87%);
        width: 415px;
    }
}

@media (max-width: 1699px) {
    .launchedRocket {
        top: calc(-154% - 32px);
        left: calc(85%);
        width: 380px;
    }
}

@media (max-width: 1600px) {
    .launchedRocket {
        top: calc(-131% - 41px);
        left: calc(87%);
        width: 300px;
    
    }
}

@media (max-width: 1500px) {
    .launchedRocket {
        top: calc(-103% - 70px);
        left: calc(86%);
        width: 220px;
    }
}

@media (max-width: 1408px) {
    .launchedRocket {
        top: calc(-92% - 44.5px);
        left: calc(87%);
        width: 200px;
    }
}

@media (max-width: 1215px) {
    .launchedRocket {display:none;}
}

@media (max-width: 1023px) {
    .phone-call-cta {
        
        display:flex!important;
    }
    .startup-description {
        margin-top: 5px!important;        
      }
    .startup-description:after {
	left:70%!important;
	}
}
@media (max-width: 767px) {
    .question {
       
        font-size: 18px!important;
    }
    .mvp-form-progress { 
      top:0!important;
      
    }
    .team-msg-hiba, .team-msg-hazem, .team-msg-mehdi {
      font-size : 18px!important;
    }
    .startup-description, .lead-description{
        font-size:14px;
        text-align: left;
        
    }
    .mvp-form-edit {
    min-height: 120px;
    min-width: 280px;
    position: absolute;
    left: 0;
    z-index:999;
    }
    .startup-description:before {
        font-size: 60px;}
    .mvp-form-input {
        font-size:1.5em;
        width:80%;
    }
    .step-7, .step-11, .step-12, {
        margin-left:-10px;
    }
}
@media (max-width: 450px) {
    .navbar-brand .navbar-item {
        
        padding-left: 0px!important;
        padding-right: 0px!important;
    }
    .hero-body {
        padding-left: 0px!important;
        padding-right: 0px!important;
    }
    .landing-cta-input {
        width:90%!important;
    }
    .hero-image {
        padding-top:20px;
    }
    .hero-subtitle {
      padding-bottom:30px;  
    }
    .legend-text{
        margin-left:4px!important;
        font-size: 13px!important;
    }
    .mvp-form-input {
        width: 98%!important;
        
    }
}
@media (max-width: 550px) {
    .dark-modal-close
    {top:40px!important;} 
    .dark-modal-box
    {width:350px!important;}
    .dark-modal-wrapper
    {height: auto!important;
        z-index: 9999!important;}
    .dark-modal-icon {
        width: 45px!important;
        height: 45px!important;
        margin-bottom: 0px!important;
    }
    .dark-modal-title {
        font-size:1.25em!important;
    }
    .dark-modal-content p {
        font-size: 16px;
        padding: 0px 20px 0px 20px;
        margin-bottom: 15px;
    }
    blockquote {
        font-size:16px!important;
    }
}

@media (max-height: 700px) {
    .prev-quick {
        bottom: 5px!important;
    width: 48px!important;
    height: 48px!important;

    }
    .mvp-form-next-button
    {margin:35px auto auto auto!important;}
    .mvp-prev {
        bottom: 5px!important;
        width: 48px!important;
        height: 48px!important;
      }
  
}

@media (max-width: 350px) {
    .fieldset-apply
    {margin-left:-7%;}
    .mvp-form-input {
        width: 98%!important;
        margin-left: 4%!important;
    }
}
@media (max-width: 359px) {
    .mvp-form-edit
    {z-index: 999; }
    .step-5-show-2 {
padding-top:0px!important;
margin-bottom:10px!important;
    }
  .step-5-show-1{
    margin-bottom: 10px!important;
  }
 
  .lead-description {
    margin-right:15px!important;
    border: none;
    margin-top: 0px!important;

}
  .step-2,.step-5, .step-9, .step-7, .step-6, .step-final {
      padding:5px;
  }
  #formStep2 h2 {
    font-size: 1em!important;
  }
  .mvp-form-square, .mvp-form-radio {
      margin-right:4px!important;
  }
  .mvp-form-subtitle {
      padding-bottom:10px;
  }
  .mvp-radio-control {
      padding-top:0px!important;
  }
  .mvp-prev {
    bottom: 5px!important;
    width: 48px!important;
    height: 48px!important;
  }


}