.featureCard    {
border-radius: 4px;
-webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
-webkit-transition: all 0.3s ease;
transition: all 0.3s ease;
margin-right: 15px;
background-color: white;
box-shadow: 0 21px 35px 0 rgba(0, 0, 0, 0.2);
border: 1px solid #e6eaeb;
text-align: left;
transition: all 0.3s ease;
padding: 30px 35px;
  &:hover {
    transition: all linear .3s;
    box-shadow: 0 21px 35px 0 rgba(0, 0, 0, 0.13);
    cursor: pointer;
}
}
.chip-svg {
    position: relative;
    left: 80%;
    top: 172px;
    height: 64px;
    width: 64px;
    font-weight: 700;
    border-radius: 0;
    background: transparent;
    opacity: 0.85;
    
}
