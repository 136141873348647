
.stamp-moving {
    transform: skew(-3deg) translate(-118px, 140px);
    opacity : 1;
}
.forceRemove {
    opacity: 0!important;
    
}
.stamp {
    
    transition: all ease-in-out .75s;
}
.invisible {
    opacity: 0;
}
