$color_1: #fff;
$color_2: #0a0a0a;
$color_3: #363636;
$color_4: #f5f5f5;
$color_5: rgba(0,0,0,.7);
$border_color_1: rgba(74,74,74,.9) transparent transparent transparent;
$border_color_2: transparent $secondary-accent transparent transparent;
$border_color_3: transparent transparent rgba(74,74,74,.9) transparent;
$border_color_4: transparent transparent transparent rgba(74,74,74,.9);
$border_color_5: rgba(255,255,255,.9) transparent transparent transparent;
$border_color_6: transparent rgba(255,255,255,.9) transparent transparent;
$border_color_7: transparent transparent rgba(255,255,255,.9) transparent;
$border_color_8: transparent transparent transparent rgba(255,255,255,.9);
$border_color_9: rgba(10,10,10,.9) transparent transparent transparent;
$border_color_10: transparent rgba(10,10,10,.9) transparent transparent;
$border_color_11: transparent transparent rgba(10,10,10,.9) transparent;
$border_color_12: transparent transparent transparent rgba(10,10,10,.9);
$border_color_13: rgba(245,245,245,.9) transparent transparent transparent;
$border_color_14: transparent rgba(245,245,245,.9) transparent transparent;
$border_color_15: transparent transparent rgba(245,245,245,.9) transparent;
$border_color_16: transparent transparent transparent rgba(245,245,245,.9);
$border_color_17: rgba(54,54,54,.9) transparent transparent transparent;
$border_color_18: transparent rgba(54,54,54,.9) transparent transparent;
$border_color_19: transparent transparent rgba(54,54,54,.9) transparent;
$border_color_20: transparent transparent transparent rgba(54,54,54,.9);
$border_color_21: rgba(0,209,178,.9) transparent transparent transparent;
$border_color_22: transparent rgba(0,209,178,.9) transparent transparent;
$border_color_23: transparent transparent rgba(0,209,178,.9) transparent;
$border_color_24: transparent transparent transparent rgba(0,209,178,.9);
$border_color_25: rgba(50,115,220,.9) transparent transparent transparent;
$border_color_26: transparent rgba(50,115,220,.9) transparent transparent;
$border_color_27: transparent transparent rgba(50,115,220,.9) transparent;
$border_color_28: transparent transparent transparent rgba(50,115,220,.9);
$border_color_29: rgba(32,156,238,.9) transparent transparent transparent;
$border_color_30: transparent rgba(32,156,238,.9) transparent transparent;
$border_color_31: transparent transparent rgba(32,156,238,.9) transparent;
$border_color_32: transparent transparent transparent rgba(32,156,238,.9);
$border_color_33: rgba(35,209,96,.9) transparent transparent transparent;
$border_color_34: transparent rgba(35,209,96,.9) transparent transparent;
$border_color_35: transparent transparent rgba(35,209,96,.9) transparent;
$border_color_36: transparent transparent transparent rgba(35,209,96,.9);
$border_color_37: rgba(255,221,87,.9) transparent transparent transparent;
$border_color_38: transparent rgba(255,221,87,.9) transparent transparent;
$border_color_39: transparent transparent rgba(255,221,87,.9) transparent;
$border_color_40: transparent transparent transparent rgba(255,221,87,.9);
$border_color_41: rgba(255,56,96,.9) transparent transparent transparent;
$border_color_42: transparent rgba(255,56,96,.9) transparent transparent;
$border_color_43: transparent transparent rgba(255,56,96,.9) transparent;
$border_color_44: transparent transparent transparent rgba(255,56,96,.9);

$primary: #34495e;
$primary-accent: #00c3a0;
$secondary: #02213b;
$secondary-accent: #ff8602;
$tertiary: #333;

@-webkit-keyframes "spinAround" {
	from {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
@keyframes "spinAround" {
	from {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
.tooltip {
	overflow: visible;
	position: relative;
	&:hover {
		&:not(.is-loading) {
			&::after {
				z-index: 99999;
				position: absolute;
				display: inline-block;
				pointer-events: none;
				content: "";
				border-style: solid;
				border-width: .5rem;
				opacity: 1;
				margin-left: -.4rem;
				margin-top: -.5rem;
				top: 0;
				bottom: auto;
				left: 50%;
				right: auto;
				border-color: $border_color_1;
			}
		}
		&::before {
			z-index: 99999;
			position: absolute;
			display: inline-block;
			pointer-events: none;
			opacity: 0;
			content: attr(data-tooltip);
			overflow: hidden;
			white-space: normal;
			text-overflow: ellipsis;
			font-size: .75rem;
			padding: .4rem .8rem;
			background: $secondary-accent;
			border-radius: 4px;
			color: $color_1;
			width: 20rem;
			opacity: 1;
			-webkit-transform: translate(-50%,-.5rem);
			transform: translate(-50%,-.5rem);
			font-family:"Open Sans";
		}
	}
	&:focus {
		&:not(.is-loading) {
			&::after {
				opacity: 1;
				margin-left: -.5rem;
				margin-top: -.5rem;
				top: 0;
				bottom: auto;
				left: 50%;
				right: auto;
				border-color: $border_color_1;
			}
		}
		&::before {
			opacity: 1;
			-webkit-transform: translate(-50%,-.5rem);
			transform: translate(-50%,-.5rem);
		}
	}
	&::before {
		top: auto;
		bottom: 100%;
		left: 50%;
		right: auto;
	}
}
.tooltip.is-tooltip-active {
	&:not(.is-loading) {
		&::after {
			z-index: 99999;
			position: absolute;
			display: inline-block;
			pointer-events: none;
			content: "";
			border-style: solid;
			border-width: .5rem;
			opacity: 1;
			margin-left: -.5rem;
			margin-top: -.5rem;
			top: 0;
			bottom: auto;
			left: 50%;
			right: auto;
			border-color: $border_color_1;
		}
	}
	&::before {
		z-index: 99999;
		position: absolute;
		display: inline-block;
		pointer-events: none;
		opacity: 0;
		content: attr(data-tooltip);
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: .75rem;
		padding: .4rem .8rem;
		background: rgba(74,74,74,.9);
		border-radius: 4px;
		color: $color_1;
		max-width: 24rem;
		opacity: 1;
		-webkit-transform: translate(-50%,-.5rem);
		transform: translate(-50%,-.5rem);
	}
}
.tooltip.is-tooltip-right {
	&::before {
		top: auto;
		bottom: 50%;
		left: 100%;
		right: auto;
		-webkit-transform: translate(-1rem,50%);
		transform: translate(-1rem,50%);
	}
	&:focus {
		&:not(.is-loading) {
			&::after {
				top: 50%;
				left: 100%;
				right: auto;
				border-color: $border_color_2;
			}
		}
		&::before {
			-webkit-transform: translate(.5rem,50%);
			transform: translate(.5rem,50%);
		}
	}
	&:hover {
		&:not(.is-loading) {
			&::after {
				top: 50%;
				left: 100%;
				right: auto;
				border-color: $border_color_2;
			}
		}
		&::before {
			-webkit-transform: translate(.5rem,50%);
			transform: translate(.5rem,50%);
		}
	}
}
.tooltip.is-tooltip-right.is-tooltip-active {
	&:not(.is-loading) {
		&::after {
			top: 50%;
			left: 100%;
			right: auto;
			border-color: $border_color_2;
		}
	}
	&::before {
		-webkit-transform: translate(.5rem,50%);
		transform: translate(.5rem,50%);
	}
}
.tooltip.is-tooltip-bottom {
	&::before {
		top: 100%;
		bottom: auto;
		left: 50%;
		right: auto;
		-webkit-transform: translate(-50%,-1rem);
		transform: translate(-50%,-1rem);
	}
	&:focus {
		&:not(.is-loading) {
			&::after {
				top: 100%;
				bottom: auto;
				left: 50%;
				right: auto;
				border-color: $border_color_3;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,.5rem);
			transform: translate(-50%,.5rem);
		}
	}
	&:hover {
		&:not(.is-loading) {
			&::after {
				top: 100%;
				bottom: auto;
				left: 50%;
				right: auto;
				border-color: $border_color_3;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,.5rem);
			transform: translate(-50%,.5rem);
		}
	}
}
.tooltip.is-tooltip-bottom.is-tooltip-active {
	&:not(.is-loading) {
		&::after {
			top: 100%;
			bottom: auto;
			left: 50%;
			right: auto;
			border-color: $border_color_3;
		}
	}
	&::before {
		-webkit-transform: translate(-50%,.5rem);
		transform: translate(-50%,.5rem);
	}
}
.tooltip.is-tooltip-left {
	&::before {
		top: auto;
		bottom: 50%;
		left: auto;
		right: 100%;
		-webkit-transform: translate(1rem,50%);
		transform: translate(1rem,50%);
	}
	&:focus {
		&:not(.is-loading) {
			&::after {
				top: 50%;
				bottom: auto;
				left: auto;
				right: calc(100% - .5rem);
				border-color: $border_color_4;
			}
		}
		&::before {
			-webkit-transform: translate(-.5rem,50%);
			transform: translate(-.5rem,50%);
		}
	}
	&:hover {
		&:not(.is-loading) {
			&::after {
				top: 50%;
				bottom: auto;
				left: auto;
				right: calc(100% - .5rem);
				border-color: $border_color_4;
			}
		}
		&::before {
			-webkit-transform: translate(-.5rem,50%);
			transform: translate(-.5rem,50%);
		}
	}
}
.tooltip.is-tooltip-left.is-tooltip-active {
	&:not(.is-loading) {
		&::after {
			top: 50%;
			bottom: auto;
			left: auto;
			right: calc(100% - .5rem);
			border-color: $border_color_4;
		}
	}
	&::before {
		-webkit-transform: translate(-.5rem,50%);
		transform: translate(-.5rem,50%);
	}
}
.tooltip.is-tooltip-multiline {
	&::before {
		min-width: 24rem;
		text-overflow: clip;
		white-space: normal;
		word-break: keep-all;
	}
}
.tooltip.is-tooltip-white {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_5;
		}
	}
	&::before {
		background: rgba(255,255,255,.9);
		color: $color_2;
	}
}
.tooltip.is-tooltip-white.is-tooltip-right {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_6;
		}
	}
}
.tooltip.is-tooltip-white.is-tooltip-bottom {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_7;
		}
	}
}
.tooltip.is-tooltip-white.is-tooltip-left {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_8;
		}
	}
}
.tooltip.is-tooltip-black {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_9;
		}
	}
	&::before {
		background: rgba(10,10,10,.9);
		color: $color_1;
	}
}
.tooltip.is-tooltip-black.is-tooltip-right {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_10;
		}
	}
}
.tooltip.is-tooltip-black.is-tooltip-bottom {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_11;
		}
	}
}
.tooltip.is-tooltip-black.is-tooltip-left {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_12;
		}
	}
}
.tooltip.is-tooltip-light {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_13;
		}
	}
	&::before {
		background: rgba(245,245,245,.9);
		color: $color_3;
	}
}
.tooltip.is-tooltip-light.is-tooltip-right {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_14;
		}
	}
}
.tooltip.is-tooltip-light.is-tooltip-bottom {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_15;
		}
	}
}
.tooltip.is-tooltip-light.is-tooltip-left {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_16;
		}
	}
}
.tooltip.is-tooltip-dark {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_17;
		}
	}
	&::before {
		background: rgba(54,54,54,.9);
		color: $color_4;
	}
}
.tooltip.is-tooltip-dark.is-tooltip-right {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_18;
		}
	}
}
.tooltip.is-tooltip-dark.is-tooltip-bottom {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_19;
		}
	}
}
.tooltip.is-tooltip-dark.is-tooltip-left {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_20;
		}
	}
}
.tooltip.is-tooltip-primary {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_21;
		}
	}
	&::before {
		background: rgba(0,209,178,.9);
		color: $color_1;
	}
}
.tooltip.is-tooltip-primary.is-tooltip-right {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_22;
		}
	}
}
.tooltip.is-tooltip-primary.is-tooltip-bottom {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_23;
		}
	}
}
.tooltip.is-tooltip-primary.is-tooltip-left {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_24;
		}
	}
}
.tooltip.is-tooltip-link {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_25;
		}
	}
	&::before {
		background: rgba(50,115,220,.9);
		color: $color_1;
	}
}
.tooltip.is-tooltip-link.is-tooltip-right {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_26;
		}
	}
}
.tooltip.is-tooltip-link.is-tooltip-bottom {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_27;
		}
	}
}
.tooltip.is-tooltip-link.is-tooltip-left {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_28;
		}
	}
}
.tooltip.is-tooltip-info {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_29;
		}
	}
	&::before {
		background: rgba(32,156,238,.9);
		color: $color_1;
	}
}
.tooltip.is-tooltip-info.is-tooltip-right {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_30;
		}
	}
}
.tooltip.is-tooltip-info.is-tooltip-bottom {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_31;
		}
	}
}
.tooltip.is-tooltip-info.is-tooltip-left {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_32;
		}
	}
}
.tooltip.is-tooltip-success {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_33;
		}
	}
	&::before {
		background: rgba(35,209,96,.9);
		color: $color_1;
	}
}
.tooltip.is-tooltip-success.is-tooltip-right {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_34;
		}
	}
}
.tooltip.is-tooltip-success.is-tooltip-bottom {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_35;
		}
	}
}
.tooltip.is-tooltip-success.is-tooltip-left {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_36;
		}
	}
}
.tooltip.is-tooltip-warning {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_37;
		}
	}
	&::before {
		background: rgba(255,221,87,.9);
		color: $color_5;
	}
}
.tooltip.is-tooltip-warning.is-tooltip-right {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_38;
		}
	}
}
.tooltip.is-tooltip-warning.is-tooltip-bottom {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_39;
		}
	}
}
.tooltip.is-tooltip-warning.is-tooltip-left {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_40;
		}
	}
}
.tooltip.is-tooltip-danger {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_41;
		}
	}
	&::before {
		background: rgba(255,56,96,.9);
		color: $color_1;
	}
}
.tooltip.is-tooltip-danger.is-tooltip-right {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_42;
		}
	}
}
.tooltip.is-tooltip-danger.is-tooltip-bottom {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_43;
		}
	}
}
.tooltip.is-tooltip-danger.is-tooltip-left {
	&:not(.is-loading) {
		&::after {
			border-color: $border_color_44;
		}
	}
}
@media screen and (max-width:768px) {
	.is-tooltip-top-mobile {
		&::before {
			top: auto !important;
			bottom: 100% !important;
			left: 50% !important;
			right: auto !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
	}
	.is-tooltip-top-mobile.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 0 !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_1 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,-.5rem) !important;
			transform: translate(-50%,-.5rem) !important;
		}
	}
	.is-tooltip-right-mobile {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: 100% !important;
			right: auto !important;
			-webkit-transform: translate(-1rem,50%) !important;
			transform: translate(-1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-right-mobile.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				left: 100% !important;
				right: auto !important;
				border-color: $border_color_2 !important;
			}
		}
		&::before {
			-webkit-transform: translate(.5rem,50%) !important;
			transform: translate(.5rem,50%) !important;
		}
	}
	.is-tooltip-bottom-mobile {
		&::before {
			top: 100% !important;
			bottom: auto !important;
			left: 50% !important;
			right: auto !important;
			-webkit-transform: translate(-50%,-1rem) !important;
			transform: translate(-50%,-1rem) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
	}
	.is-tooltip-bottom-mobile.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 100% !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_3 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,.5rem) !important;
			transform: translate(-50%,.5rem) !important;
		}
	}
	.is-tooltip-left-mobile {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: auto !important;
			right: 100% !important;
			-webkit-transform: translate(1rem,50%) !important;
			transform: translate(1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-left-mobile.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				bottom: auto !important;
				left: auto !important;
				right: calc(100% - .5rem) !important;
				border-color: $border_color_4 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-.5rem,50%) !important;
			transform: translate(-.5rem,50%) !important;
		}
	}
}
@media screen and (min-width:769px) {
	.is-tooltip-top-tablet {
		&::before {
			top: auto !important;
			bottom: 100% !important;
			left: 50% !important;
			right: auto !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
	}
	.is-tooltip-top-tablet.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 0 !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_1 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,-.5rem) !important;
			transform: translate(-50%,-.5rem) !important;
		}
	}
	.is-tooltip-right-tablet {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: 100% !important;
			right: auto !important;
			-webkit-transform: translate(-1rem,50%) !important;
			transform: translate(-1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-right-tablet.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				left: 100% !important;
				right: auto !important;
				border-color: $border_color_2 !important;
			}
		}
		&::before {
			-webkit-transform: translate(.5rem,50%) !important;
			transform: translate(.5rem,50%) !important;
		}
	}
	.is-tooltip-bottom-tablet {
		&::before {
			top: 100% !important;
			bottom: auto !important;
			left: 50% !important;
			right: auto !important;
			-webkit-transform: translate(-50%,-1rem) !important;
			transform: translate(-50%,-1rem) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
	}
	.is-tooltip-bottom-tablet.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 100% !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_3 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,.5rem) !important;
			transform: translate(-50%,.5rem) !important;
		}
	}
	.is-tooltip-left-tablet {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: auto !important;
			right: 100% !important;
			-webkit-transform: translate(1rem,50%) !important;
			transform: translate(1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-left-tablet.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				bottom: auto !important;
				left: auto !important;
				right: calc(100% - .5rem) !important;
				border-color: $border_color_4 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-.5rem,50%) !important;
			transform: translate(-.5rem,50%) !important;
		}
	}
}
@media print {
	.is-tooltip-top-tablet {
		&::before {
			top: auto !important;
			bottom: 100% !important;
			left: 50% !important;
			right: auto !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
	}
	.is-tooltip-top-tablet.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 0 !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_1 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,-.5rem) !important;
			transform: translate(-50%,-.5rem) !important;
		}
	}
	.is-tooltip-right-tablet {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: 100% !important;
			right: auto !important;
			-webkit-transform: translate(-1rem,50%) !important;
			transform: translate(-1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-right-tablet.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				left: 100% !important;
				right: auto !important;
				border-color: $border_color_2 !important;
			}
		}
		&::before {
			-webkit-transform: translate(.5rem,50%) !important;
			transform: translate(.5rem,50%) !important;
		}
	}
	.is-tooltip-bottom-tablet {
		&::before {
			top: 100% !important;
			bottom: auto !important;
			left: 50% !important;
			right: auto !important;
			-webkit-transform: translate(-50%,-1rem) !important;
			transform: translate(-50%,-1rem) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
	}
	.is-tooltip-bottom-tablet.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 100% !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_3 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,.5rem) !important;
			transform: translate(-50%,.5rem) !important;
		}
	}
	.is-tooltip-left-tablet {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: auto !important;
			right: 100% !important;
			-webkit-transform: translate(1rem,50%) !important;
			transform: translate(1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-left-tablet.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				bottom: auto !important;
				left: auto !important;
				right: calc(100% - .5rem) !important;
				border-color: $border_color_4 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-.5rem,50%) !important;
			transform: translate(-.5rem,50%) !important;
		}
	}
}
@media screen and (min-width:769px) and (max-width:1087px) {
	.is-tooltip-top-tablet-only {
		&::before {
			top: auto !important;
			bottom: 100% !important;
			left: 50% !important;
			right: auto !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
	}
	.is-tooltip-top-tablet-only.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 0 !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_1 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,-.5rem) !important;
			transform: translate(-50%,-.5rem) !important;
		}
	}
	.is-tooltip-right-tablet-only {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: 100% !important;
			right: auto !important;
			-webkit-transform: translate(-1rem,50%) !important;
			transform: translate(-1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-right-tablet-only.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				left: 100% !important;
				right: auto !important;
				border-color: $border_color_2 !important;
			}
		}
		&::before {
			-webkit-transform: translate(.5rem,50%) !important;
			transform: translate(.5rem,50%) !important;
		}
	}
	.is-tooltip-bottom-tablet-only {
		&::before {
			top: 100% !important;
			bottom: auto !important;
			left: 50% !important;
			right: auto !important;
			-webkit-transform: translate(-50%,-1rem) !important;
			transform: translate(-50%,-1rem) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
	}
	.is-tooltip-bottom-tablet-only.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 100% !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_3 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,.5rem) !important;
			transform: translate(-50%,.5rem) !important;
		}
	}
	.is-tooltip-left-tablet-only {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: auto !important;
			right: 100% !important;
			-webkit-transform: translate(1rem,50%) !important;
			transform: translate(1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-left-tablet-only.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				bottom: auto !important;
				left: auto !important;
				right: calc(100% - .5rem) !important;
				border-color: $border_color_4 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-.5rem,50%) !important;
			transform: translate(-.5rem,50%) !important;
		}
	}
}
@media screen and (max-width:1087px) {
	.is-tooltip-top-touch {
		&::before {
			top: auto !important;
			bottom: 100% !important;
			left: 50% !important;
			right: auto !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
	}
	.is-tooltip-top-touch.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 0 !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_1 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,-.5rem) !important;
			transform: translate(-50%,-.5rem) !important;
		}
	}
	.is-tooltip-right-touch {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: 100% !important;
			right: auto !important;
			-webkit-transform: translate(-1rem,50%) !important;
			transform: translate(-1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-right-touch.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				left: 100% !important;
				right: auto !important;
				border-color: $border_color_2 !important;
			}
		}
		&::before {
			-webkit-transform: translate(.5rem,50%) !important;
			transform: translate(.5rem,50%) !important;
		}
	}
	.is-tooltip-bottom-touch {
		&::before {
			top: 100% !important;
			bottom: auto !important;
			left: 50% !important;
			right: auto !important;
			-webkit-transform: translate(-50%,-1rem) !important;
			transform: translate(-50%,-1rem) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
	}
	.is-tooltip-bottom-touch.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 100% !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_3 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,.5rem) !important;
			transform: translate(-50%,.5rem) !important;
		}
	}
	.is-tooltip-left-touch {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: auto !important;
			right: 100% !important;
			-webkit-transform: translate(1rem,50%) !important;
			transform: translate(1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-left-touch.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				bottom: auto !important;
				left: auto !important;
				right: calc(100% - .5rem) !important;
				border-color: $border_color_4 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-.5rem,50%) !important;
			transform: translate(-.5rem,50%) !important;
		}
	}
}
@media screen and (min-width:1088px) {
	.is-tooltip-top-desktop {
		&::before {
			top: auto !important;
			bottom: 100% !important;
			left: 50% !important;
			right: auto !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
	}
	.is-tooltip-top-desktop.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 0 !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_1 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,-.5rem) !important;
			transform: translate(-50%,-.5rem) !important;
		}
	}
	.is-tooltip-right-desktop {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: 100% !important;
			right: auto !important;
			-webkit-transform: translate(-1rem,50%) !important;
			transform: translate(-1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-right-desktop.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				left: 100% !important;
				right: auto !important;
				border-color: $border_color_2 !important;
			}
		}
		&::before {
			-webkit-transform: translate(.5rem,50%) !important;
			transform: translate(.5rem,50%) !important;
		}
	}
	.is-tooltip-bottom-desktop {
		&::before {
			top: 100% !important;
			bottom: auto !important;
			left: 50% !important;
			right: auto !important;
			-webkit-transform: translate(-50%,-1rem) !important;
			transform: translate(-50%,-1rem) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
	}
	.is-tooltip-bottom-desktop.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 100% !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_3 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,.5rem) !important;
			transform: translate(-50%,.5rem) !important;
		}
	}
	.is-tooltip-left-desktop {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: auto !important;
			right: 100% !important;
			-webkit-transform: translate(1rem,50%) !important;
			transform: translate(1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-left-desktop.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				bottom: auto !important;
				left: auto !important;
				right: calc(100% - .5rem) !important;
				border-color: $border_color_4 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-.5rem,50%) !important;
			transform: translate(-.5rem,50%) !important;
		}
	}
}
@media screen and (min-width:1088px) and (max-width:1279px) {
	.is-tooltip-top-desktop-only {
		&::before {
			top: auto !important;
			bottom: 100% !important;
			left: 50% !important;
			right: auto !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
	}
	.is-tooltip-top-desktop-only.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 0 !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_1 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,-.5rem) !important;
			transform: translate(-50%,-.5rem) !important;
		}
	}
	.is-tooltip-right-desktop-only {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: 100% !important;
			right: auto !important;
			-webkit-transform: translate(-1rem,50%) !important;
			transform: translate(-1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-right-desktop-only.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				left: 100% !important;
				right: auto !important;
				border-color: $border_color_2 !important;
			}
		}
		&::before {
			-webkit-transform: translate(.5rem,50%) !important;
			transform: translate(.5rem,50%) !important;
		}
	}
	.is-tooltip-bottom-desktop-only {
		&::before {
			top: 100% !important;
			bottom: auto !important;
			left: 50% !important;
			right: auto !important;
			-webkit-transform: translate(-50%,-1rem) !important;
			transform: translate(-50%,-1rem) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
	}
	.is-tooltip-bottom-desktop-only.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 100% !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_3 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,.5rem) !important;
			transform: translate(-50%,.5rem) !important;
		}
	}
	.is-tooltip-left-desktop-only {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: auto !important;
			right: 100% !important;
			-webkit-transform: translate(1rem,50%) !important;
			transform: translate(1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-left-desktop-only.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				bottom: auto !important;
				left: auto !important;
				right: calc(100% - .5rem) !important;
				border-color: $border_color_4 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-.5rem,50%) !important;
			transform: translate(-.5rem,50%) !important;
		}
	}
}
@media screen and (max-width:1279px) {
	.is-tooltip-top-until-widescreen {
		&::before {
			top: auto !important;
			bottom: 100% !important;
			left: 50% !important;
			right: auto !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
	}
	.is-tooltip-top-until-widescreen.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 0 !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_1 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,-.5rem) !important;
			transform: translate(-50%,-.5rem) !important;
		}
	}
	.is-tooltip-right-until-widescreen {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: 100% !important;
			right: auto !important;
			-webkit-transform: translate(-1rem,50%) !important;
			transform: translate(-1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-right-until-widescreen.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				left: 100% !important;
				right: auto !important;
				border-color: $border_color_2 !important;
			}
		}
		&::before {
			-webkit-transform: translate(.5rem,50%) !important;
			transform: translate(.5rem,50%) !important;
		}
	}
	.is-tooltip-bottom-until-widescreen {
		&::before {
			top: 100% !important;
			bottom: auto !important;
			left: 50% !important;
			right: auto !important;
			-webkit-transform: translate(-50%,-1rem) !important;
			transform: translate(-50%,-1rem) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
	}
	.is-tooltip-bottom-until-widescreen.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 100% !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_3 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,.5rem) !important;
			transform: translate(-50%,.5rem) !important;
		}
	}
	.is-tooltip-left-until-widescreen {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: auto !important;
			right: 100% !important;
			-webkit-transform: translate(1rem,50%) !important;
			transform: translate(1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-left-until-widescreen.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				bottom: auto !important;
				left: auto !important;
				right: calc(100% - .5rem) !important;
				border-color: $border_color_4 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-.5rem,50%) !important;
			transform: translate(-.5rem,50%) !important;
		}
	}
}
@media screen and (min-width:1280px) {
	.is-tooltip-top-widescreen {
		&::before {
			top: auto !important;
			bottom: 100% !important;
			left: 50% !important;
			right: auto !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
	}
	.is-tooltip-top-widescreen.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 0 !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_1 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,-.5rem) !important;
			transform: translate(-50%,-.5rem) !important;
		}
	}
	.is-tooltip-right-widescreen {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: 100% !important;
			right: auto !important;
			-webkit-transform: translate(-1rem,50%) !important;
			transform: translate(-1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-right-widescreen.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				left: 100% !important;
				right: auto !important;
				border-color: $border_color_2 !important;
			}
		}
		&::before {
			-webkit-transform: translate(.5rem,50%) !important;
			transform: translate(.5rem,50%) !important;
		}
	}
	.is-tooltip-bottom-widescreen {
		&::before {
			top: 100% !important;
			bottom: auto !important;
			left: 50% !important;
			right: auto !important;
			-webkit-transform: translate(-50%,-1rem) !important;
			transform: translate(-50%,-1rem) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
	}
	.is-tooltip-bottom-widescreen.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 100% !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_3 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,.5rem) !important;
			transform: translate(-50%,.5rem) !important;
		}
	}
	.is-tooltip-left-widescreen {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: auto !important;
			right: 100% !important;
			-webkit-transform: translate(1rem,50%) !important;
			transform: translate(1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-left-widescreen.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				bottom: auto !important;
				left: auto !important;
				right: calc(100% - .5rem) !important;
				border-color: $border_color_4 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-.5rem,50%) !important;
			transform: translate(-.5rem,50%) !important;
		}
	}
}
@media screen and (min-width:1280px) and (max-width:1471px) {
	.is-tooltip-top-widescreen-only {
		&::before {
			top: auto !important;
			bottom: 100% !important;
			left: 50% !important;
			right: auto !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
	}
	.is-tooltip-top-widescreen-only.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 0 !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_1 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,-.5rem) !important;
			transform: translate(-50%,-.5rem) !important;
		}
	}
	.is-tooltip-right-widescreen-only {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: 100% !important;
			right: auto !important;
			-webkit-transform: translate(-1rem,50%) !important;
			transform: translate(-1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-right-widescreen-only.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				left: 100% !important;
				right: auto !important;
				border-color: $border_color_2 !important;
			}
		}
		&::before {
			-webkit-transform: translate(.5rem,50%) !important;
			transform: translate(.5rem,50%) !important;
		}
	}
	.is-tooltip-bottom-widescreen-only {
		&::before {
			top: 100% !important;
			bottom: auto !important;
			left: 50% !important;
			right: auto !important;
			-webkit-transform: translate(-50%,-1rem) !important;
			transform: translate(-50%,-1rem) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
	}
	.is-tooltip-bottom-widescreen-only.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 100% !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_3 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,.5rem) !important;
			transform: translate(-50%,.5rem) !important;
		}
	}
	.is-tooltip-left-widescreen-only {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: auto !important;
			right: 100% !important;
			-webkit-transform: translate(1rem,50%) !important;
			transform: translate(1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-left-widescreen-only.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				bottom: auto !important;
				left: auto !important;
				right: calc(100% - .5rem) !important;
				border-color: $border_color_4 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-.5rem,50%) !important;
			transform: translate(-.5rem,50%) !important;
		}
	}
}
@media screen and (max-width:1471px) {
	.is-tooltip-top-until-fullhd {
		&::before {
			top: auto !important;
			bottom: 100% !important;
			left: 50% !important;
			right: auto !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
	}
	.is-tooltip-top-until-fullhd.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 0 !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_1 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,-.5rem) !important;
			transform: translate(-50%,-.5rem) !important;
		}
	}
	.is-tooltip-right-until-fullhd {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: 100% !important;
			right: auto !important;
			-webkit-transform: translate(-1rem,50%) !important;
			transform: translate(-1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-right-until-fullhd.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				left: 100% !important;
				right: auto !important;
				border-color: $border_color_2 !important;
			}
		}
		&::before {
			-webkit-transform: translate(.5rem,50%) !important;
			transform: translate(.5rem,50%) !important;
		}
	}
	.is-tooltip-bottom-until-fullhd {
		&::before {
			top: 100% !important;
			bottom: auto !important;
			left: 50% !important;
			right: auto !important;
			-webkit-transform: translate(-50%,-1rem) !important;
			transform: translate(-50%,-1rem) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
	}
	.is-tooltip-bottom-until-fullhd.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 100% !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_3 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,.5rem) !important;
			transform: translate(-50%,.5rem) !important;
		}
	}
	.is-tooltip-left-until-fullhd {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: auto !important;
			right: 100% !important;
			-webkit-transform: translate(1rem,50%) !important;
			transform: translate(1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-left-until-fullhd.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				bottom: auto !important;
				left: auto !important;
				right: calc(100% - .5rem) !important;
				border-color: $border_color_4 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-.5rem,50%) !important;
			transform: translate(-.5rem,50%) !important;
		}
	}
}
@media screen and (min-width:1472px) {
	.is-tooltip-top-fullhd {
		&::before {
			top: auto !important;
			bottom: 100% !important;
			left: 50% !important;
			right: auto !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 0 !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_1 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,-.5rem) !important;
				transform: translate(-50%,-.5rem) !important;
			}
		}
	}
	.is-tooltip-top-fullhd.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 0 !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_1 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,-.5rem) !important;
			transform: translate(-50%,-.5rem) !important;
		}
	}
	.is-tooltip-right-fullhd {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: 100% !important;
			right: auto !important;
			-webkit-transform: translate(-1rem,50%) !important;
			transform: translate(-1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					left: 100% !important;
					right: auto !important;
					border-color: $border_color_2 !important;
				}
			}
			&::before {
				-webkit-transform: translate(.5rem,50%) !important;
				transform: translate(.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-right-fullhd.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				left: 100% !important;
				right: auto !important;
				border-color: $border_color_2 !important;
			}
		}
		&::before {
			-webkit-transform: translate(.5rem,50%) !important;
			transform: translate(.5rem,50%) !important;
		}
	}
	.is-tooltip-bottom-fullhd {
		&::before {
			top: 100% !important;
			bottom: auto !important;
			left: 50% !important;
			right: auto !important;
			-webkit-transform: translate(-50%,-1rem) !important;
			transform: translate(-50%,-1rem) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 100% !important;
					bottom: auto !important;
					left: 50% !important;
					right: auto !important;
					border-color: $border_color_3 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-50%,.5rem) !important;
				transform: translate(-50%,.5rem) !important;
			}
		}
	}
	.is-tooltip-bottom-fullhd.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 100% !important;
				bottom: auto !important;
				left: 50% !important;
				right: auto !important;
				border-color: $border_color_3 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-50%,.5rem) !important;
			transform: translate(-50%,.5rem) !important;
		}
	}
	.is-tooltip-left-fullhd {
		&::before {
			top: auto !important;
			bottom: 50% !important;
			left: auto !important;
			right: 100% !important;
			-webkit-transform: translate(1rem,50%) !important;
			transform: translate(1rem,50%) !important;
		}
		&:focus {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
		&:hover {
			&:not(.is-loading) {
				&::after {
					top: 50% !important;
					bottom: auto !important;
					left: auto !important;
					right: calc(100% - .5rem) !important;
					border-color: $border_color_4 !important;
				}
			}
			&::before {
				-webkit-transform: translate(-.5rem,50%) !important;
				transform: translate(-.5rem,50%) !important;
			}
		}
	}
	.is-tooltip-left-fullhd.is-tooltip-active {
		&:not(.is-loading) {
			&::after {
				top: 50% !important;
				bottom: auto !important;
				left: auto !important;
				right: calc(100% - .5rem) !important;
				border-color: $border_color_4 !important;
			}
		}
		&::before {
			-webkit-transform: translate(-.5rem,50%) !important;
			transform: translate(-.5rem,50%) !important;
		}
	}
}
