
.app-container{
    font-family: 'Open Sans', sans-serif;
}
.h-100vh
{
    min-height: 100vh;
}
.is-opensans{
    font-family: 'Open Sans', sans-serif;
}
.is-exo{
    
    font-family: 'Exo 2', sans-serif;
}
.step1Container {

}
.onboarding-title {
    color: $lightgray;
}
.onboarding-subtitle {
    color:$text-color;
    font-weight: 400;
    line-height: 1.25;
    max-width:600px;
    margin:0 auto;
} 
.is-115 {
font-size:1.15em;
}