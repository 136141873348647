
.exit-loader, .mvp-form-loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #34495e;
    z-index: 200;
    opacity: 0;
    transition: all linear 0.5s;
    background-image: linear-gradient(to bottom, #283542 0%, #34495e 100%) !important;
    display:none;
    align-items: center;
}
.loader-active {
display:block;
}
.loader-visible {
	opacity:1;
}
.loader-removed {
	display:none;
}
.loader-hidden {
	opacity:0;
}
.founder-quote {
    display:none;
}
blockquote {
	position: relative;
	padding-left: 30px;
	font-weight: 900;
	color: white;
	padding: 1em;
	margin: 1em;
	max-width: 33em;
    font-size: 28px;
    font-style:italic;
	&:before {
		content: "“";
		font-family: serif;
		position: absolute;
		right: 100%;
		font-size: 120px;
		line-height: 0px;
		top: 50px;
		color: $pinkiary;
	}
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 100%;
		width: 80px;
		height: 5px;
		background: #00c3c0;
		left: 1em;
	}
}
.exit-loader-active {
    display:flex;
}
.exit-loader-visible {
    opacity : 1;
}
.quote-visible {
    display:flex;
}
.quote-meta {
    position: absolute;
    top: 100%;
    margin-top: 20px;
    font-family: proxima_novathin;
    color: #ececec;
    font-size: 22px;
    font-style: normal;
}