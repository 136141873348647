/** Radio control componenet */
.mvp-square-control, .mvp-edit-control {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.mvp-form-square {
    width:148px;
    height:148px;
    border-radius:4px;
    background-color:$primary;
    border: 2px solid #626f7a;
    color:white;
    margin:12px;
    text-align: center;
    vertical-align: middle;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    cursor:pointer;
    transition: all 0.5s ease-out !important;
    //font-size: 20px;
}
.mvp-form-square-sm {
    width:110px;
    height:100px;
    border-radius:4px;
    background-color:$primary;
    border: 2px solid #626f7a;
    color:white;
    margin:12px;
    text-align: center;
    vertical-align: middle;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    cursor:pointer;
    transition: all 0.5s ease-out !important;
    //font-size: 20px;
} 
.mvp-form-rect {
    width:148px;
    height:70px;
    border-radius:4px;
    background-color:$primary;
    border: 2px solid #626f7a;
    color:white;
    margin:12px;
    text-align: center;
    vertical-align: middle;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    cursor:pointer;
    transition: all 0.5s ease-out !important;
    //font-size: 20px;
} 
.square-control-selected{
background-color: #425c74;
border: 2px solid #00c3c0;    
}
.mvp-form-square:hover, .mvp-form-square-sm:hover{
background-color: #425c74; 
}
.mvp-form-rect:hover {
    background-color: #425c74;
    border: 2px solid #00c3c0;
}
.square-control-selected{
border: 2px solid #00c3c0;    
}
.button-i-square {
        align-self: center;
    }
.picker-icon {
 height: 32px;
 width: 32px;   
}

@media (max-width: 767px) {
    .mvp-form-square, .mvp-form-square-sm {
        width:70px;
        height:70px;
        font-size: 11px;
    }
    .mvp-form-rect {
        width:130px;
        height:70px;
        font-size: 12px;
    }
}