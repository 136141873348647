@import '../colorvars';

.stageCard {
    background-color: white;
    box-shadow: 0 21px 35px 0 rgba(0,0,0,.2);
    border: 1px solid #e6eaeb;
    text-align: left;
    transition: all 0.3s ease;
    padding: 30px 35px;
}

.step-chip {
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
}
.chip-one, .chip-two, .chip-three {
    position: relative;
    left: calc(50% - 17px);
    top: 5px;
    height: 32px;
    width: 32px;
    border: 1px solid #e6eaeb;

    font-weight: 700;

}
.chip-inactive {
    background: #fff;
    color: $primary-accent;
}
.chip-active {
    background: $primary-accent;
    color: #fff;
}