.landing-cta-form {
    padding: 5px;
    background-color: #2b3c4c;
    border: 2px solid #27c3c0;
    display: inline-flex;
    z-index:10;
}
.landing-cta-form.me-removed {
    display:none!important;
}
.landing-cta-input {
    -webkit-appearance: none;
    align-items: center;
    border-radius: 1px;
    display: inline-flex;
    font-size: 1rem;
    height: 3em;
    justify-content: flex-start;
    line-height: 1.5;
    padding: calc(.375em - 1px) calc(.625em - 1px);
    position: relative;
    vertical-align: top;
    background-color: #2b3c4c;
    color: #b1b7bd;
    max-width: 100%;
    transition: transform 0.15s;
    border: none !important;
    box-shadow: none;
    z-index:10;
}
.landing-cta {
    transition: all 0.2s ease-out !important;
    border: none;
    box-shadow: 0 1px 2px -10px rgba(0, 0, 0, 0.42), 0 1px 1px 0px rgba(0, 0, 0, 0.12), 0 2px 2px -1px rgba(0, 0, 0, 0.2) !important;
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 100;
    background-color: #00c3c0;
    color: white;
    cursor: pointer;
    z-index:10;
    font-family: 'Exo 2'!important;
    opacity:1;
        &:hover {
            opacity:.9;
        }
}
.cta-form-msg {

    padding: 18px 9px;
    text-align: center;
    background-color: $tertiary;
    font-size: 1em;
    color: $lightgray;
    transition : all linear .5s;
}