$primary: #34495e;
$primary-accent: #00c3c0;
$secondary: #2b3c4c;
$secondary-accent: #ff8602;
$tertiary: #3b5369;
$pinkiary: #fc6584;
$label-color : #ffffff5e;
$text-color : #b1b7bd;
$line-color: #314559;


.dark-form-label {
    color:$label-color!important;
}

.dark-form-input {
    -webkit-appearance: none;
    align-items: center;
    border-radius: 1px;
    display: inline-flex;
    font-size: 1rem;
    height: 3em;
    justify-content: flex-start;
    line-height: 1.5;
    padding: calc(.375em - 1px) calc(.625em - 1px);
    position: relative;
    vertical-align: top;
    background-color: #2b3c4c;
    color: #b1b7bd;
    max-width: 100%;
    width: 100%;
    transition: transform 0.15s;
    transform: scale(1);
    border: none!important;
    box-shadow: none;
}
.dark-form-input:hover {
    border-color: #34495da3;
    box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25);
    transition: all .5s;
    background-color: $primary;
}
.dark-form-input:focus,.dark-form-input:active {
    border: 2px solid #00c3c000;
    box-shadow: none;
    transform: scale(1.07);
    transition: all 0.3s;
    
}
.dark-help-login {
    color:$primary-accent;
}
.is-card-button {
    background-color: #2b3c4c;
    transition: all 0.2s ease-out !important;
    border: none;
    box-shadow: 0 1px 2px -10px rgba(0, 0, 0, 0.42), 0 1px 1px 0px rgba(0, 0, 0, 0.12), 0 2px 2px -1px rgba(0, 0, 0, 0.2) !important;
}
.is-card-button:hover{
    box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
    background-color: #00c3c0;
}