$primary: #34495e;
$primary-accent: #00c3c0;
$secondary: #2b3c4c;
$secondary-accent: #ff8602;
$tertiary: #333;


.hero #navbar-appbar {
    //position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(0%);
    z-index: 100;
    //background-color: $primary !important;
    -webkit-box-shadow: 0 0 8px 0 rgba(52, 73, 93, 0.32);
    box-shadow: 0 0 8px 0 rgba(52, 73, 93, 0.32);
}

.app-aside
{   position: fixed;
    top: 0;
    background: $primary;
    height: 100vh;
    float: left;
    z-index: 99999;
    box-shadow: 1px 0px 7px #0000002b;
   //overflow-x: hidden;
}
@media screen and (min-width: 769px)  {
.app-aside {
    flex: none;
    width: 6.3333333333%!important;
    padding : 0px!important;
    }
}
.app-menu-list {

}
.app-menu-li {
    background-color:transparent!important;
   
}
.app-menu-a {
    color:#ffffffb8!important;
    padding: 0.75em 0.25em 0.75em 0.75em !important;
    position: relative;
    display: block;
    text-decoration: none;
    overflow: hidden;
    opacity: 1;
    font-size: 12px;
    font-weight: 900;
    transition: background-color .12s ease;
    &.is-active {
        background-color: $primary-accent!important;
    }
    &:hover {
    background-color: $primary-accent!important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 24px;
    position: relative;
    transform: scale(1.1);
    z-index: 1;
    border-radius: 2px;
    }
} 
.app-menu-a-launch {
    
    padding: 0.75em 0.25em 0.75em 0.75em !important;
    position: relative;
    display: block;
    text-decoration: none;
    overflow: hidden;
    opacity: .8;
    font-size: 12px;
    font-weight: 900;
    transition: background-color .12s ease;
    color: $secondary-accent!important;
    &.is-active {
        background-color: $primary-accent!important;
    }
    &:hover {
        background-color: $secondary-accent!important;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 24px;
        position: relative;
        transform: scale(1.1);
        z-index: 1;
        border-radius: 2px;
        color:whitesmoke!important;
    }
} 
.app-menu-icon {

height: 3rem;
width: 3rem;
margin: 0 auto;
}
@media screen and (min-width: 1367px)  {
    .app-menu-icon {
        display: flex!important;
        height: 3rem;
        width: 3rem;
        }
    }
    @media screen and (max-width: 1366px)  {
        .app-menu-icon {
            display: flex!important;
            height: 1.5rem;
            width: 1.5rem;
            }
        }
.has-scroll-y {
    overflow-y: auto;
    overflow-x: hidden;
}
.appbar-item
{
    padding: 0rem 1rem!important;
    color:#34495d9e!important;
    cursor: pointer;
   
}

.appbar-drop {
    padding: 0rem 0rem!important;
    color:#34495d9e;
   
}
.dashHighlights {
    color:$secondary-accent;
}
.dark-light-text
{
    color: #949ea9;
}
.is-orange {
    color:$secondary-accent!important;
}
.appbar-rounded {
    border-radius: 25%!important;
    height: 32px!important;
    width: 32px!important;
    max-height: 32px!important;
    box-shadow: 0 2px 0 0 rgba(30,34,59,0.2);
}
.appbar-text {
    padding: 1.25rem 1rem;
}
.appbar-drop-svg {
    width: 12px;
    height: 12px;
    stroke: $tertiary;
    stroke-width: 1.4px;
    cursor: pointer;
    transition: all .3s;
}
.appbar-drop-a {
    vertical-align: top!important;
    text-transform: capitalize!important;
    font-family: Dosis!important;
    font-size: 0.85rem!important;
    padding: 0.5rem!important;
    color:$tertiary!important;
}
.external-link {
    width: 14px;
    height: 14px;
    stroke: $primary-accent;
    stroke-width: 1.4px;
    cursor: pointer;
    transition: all .3s;
    margin-left: 0.5rem;
}
.primary-text
{
    color:$primary!important;

}